import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import { RouterProvider } from "react-router-dom";

import "./App.scss";
import router from "./routing";
import { lightTheme } from "./utils/constants/theme";
import CableProvider from "./utils/providers/CableProvider";
import PostsUpdateProvider from "./utils/providers/PostsUpdateProvider";

const App = () => {
  return (
    <ConfigProvider theme={lightTheme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <CableProvider>
          <PostsUpdateProvider>
            <RouterProvider router={router} />
          </PostsUpdateProvider>
        </CableProvider>
      </GoogleOAuthProvider>
    </ConfigProvider>
  );
};

export default App;

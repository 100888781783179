import * as $vars from "./variables";

export const lightTheme = {
  token: {
    colorPrimary: "#7E7AFF",
    colorLink: "#7E7AFF",
    fontFamily: "Road UI, Segoe UI, Droid Sans, Helvetica Neue, sans-serif",
    lineHeight: "1.43",
    colorLinkHover: "#4744ce",
  },
  components: {
    Avatar: {
      lineWidth: "0",
    },
    Button: {
      defaultBg: "#F4F4F4",
      defaultBorderColor: "#F4F4F4",
      defaultColor: $vars.colors.grey,
      borderRadiusLG: 8,
      transition: "none",
      fontWeight: "500",
      paddingBlockLG: "8px",
      paddingInlineLG: "16px",
    },
    Collapse: {
      padding: 0,
      headerBg: "white",
      headerPadding: "0",
      lineWidth: "0",
      collapseHeaderPadding: "0px",
      contentPadding: "8px",
    },
    Divider: {
      colorText: $vars.colors.grey,
    },
    Drawer: {
      padding: 16,
      paddingLG: "0 16px 16px",
      paddingXS: 12,
      colorSplit: "transparent",
      colorBgElevated: $vars.colors.light,
      body: {
        padding: "0",
      },
    },
    Input: {
      paddingBlockLG: 12,
      paddingInlineLG: 14,
      lineHeightLg: "19px !important",
      borderRadiusLG: 8,
      colorErrorText: $vars.colors.red,
    },
    Layout: {
      colorBgLayout: $vars.colors.bodyBg,
      siderBg: $vars.colors.bodyBg,
    },
    Menu: {
      itemBg: "transparent",
      popupBg: "transparent",
      subMenuItemBg: "transparent",
      itemMarginInline: "0",
      itemSelectedBg: "transparent",
    },
    Modal: {
      titleFontSize: "18px",
      titleLineHeight: "20px",
      fontSize: "16px",
      fontWeightStrong: "700",
      contentBg: "#FFFFFF",
      headerBg: "transparent",
    },
    Popover: {
      innerPadding: 0,
      borderRadiusLG: 8,
    },
    Typography: {
      fontSize: "16px",
    },
    Upload: {
      borderRadiusLG: 6,
      lineWidth: 2,
      colorBorder: $vars.colors.lightPurple,
      colorFillAlter: "transparent",
    },
  },
};

export const darkTheme = {
  token: {},
};

import ROUTES from "@/routing/routes";
import cn from "@/utils/helpers/className";
import { formatDateTemplate } from "@/utils/helpers/date";
import { Flex, Tooltip } from "antd";
import { format } from "date-fns";
import { AddSquare, MinusSquare } from "iconsax-react";
import React, { memo, useState } from "react";
import { Link, generatePath } from "react-router-dom";

import { FormattedMetadata } from "../../formatted-metadata";
import PostImages from "../../post/post-images/post-images";
import { Avatar } from "../../ui/avatar";
import { Spinner } from "../../ui/spinner";
import { ProfileInfoTooltip } from "../../user-popover";
import { Comments } from "../comments";
import CommentsActions from "../comments-actions";
import { sortArrayWithPostDataToEnd } from "../constants";
import { EditComment } from "../edit-comment";
import { WriteComment } from "../write-comment";
import styles from "./thread-item.module.scss";

const ThreadItem = ({ comment, onAction, parentId }) => {
  const {
    id,
    body,
    external_images,
    metadata,
    votes_up,
    votes_down,
    reposts_count,
    comments_count,
    profile,
    published_at_str,
    comments,
    isLoading,
    comment_last_id,
    comments_left,
    error,
    published_at,
    vote,
    permissions,
    post_id,
  } = comment;
  const { display_name, username, profile_image } = profile;
  const [isExpanded, setIsExpanded] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAction = (type, metadata) => {
    if (type === "comment") {
      if (!isCommentFormOpen) {
        setIsExpanded(true);
      }
      setIsCommentFormOpen(!isCommentFormOpen);
    }

    if (type === "edit") {
      setIsEditing(true);
      return;
    }

    return onAction({
      type,
      id,
      parentId,
      metadata,
    })?.then((res) => {
      if (type === "reply" && res?.payload?.id) {
        setIsCommentFormOpen(false);
        return res;
      } else {
        return res;
      }
    });
  };

  const profile_avatar = profile_image?.small?.url;

  return (
    <Flex className={styles.threadWrap} vertical gap={12}>
      <div className={styles.firstWrap}>
        {comments?.length > 0 && (
          <>
            <div
              className={cn(
                styles.firstLevelLine,
                isExpanded ? "isExpanded" : "",
              )}
            />
            <div
              className={cn(styles.btnCollapse, isExpanded ? "isExpanded" : "")}
              onClick={handleExpand}
            >
              {isExpanded ? (
                <MinusSquare size="20" color="#91919d" />
              ) : (
                <AddSquare size="20" color="#91919d" />
              )}
            </div>
          </>
        )}
        <Flex gap={8}>
          <Link to={generatePath(ROUTES.otherProfile, { slag: username })}>
            <ProfileInfoTooltip username={username}>
              <div>
                <Avatar profile_avatar={profile_avatar} className={styles.commentAvatar} />
              </div>
            </ProfileInfoTooltip>
          </Link>
          <Flex vertical gap={2} flex={1}>
            <Flex className={styles.commentHeader} align={"center"}>
              <Link
                className={styles.commentAuthor}
                to={generatePath(ROUTES.otherProfile, { slag: username })}
              >
                <ProfileInfoTooltip username={username}>
                  {display_name}
                </ProfileInfoTooltip>
              </Link>
              <Link
                className={styles.commentDateTime}
                to={generatePath(ROUTES.otherProfile, { slag: username })}
                title={
                  published_at?.length &&
                  format(published_at, formatDateTemplate)
                }
              >
                {published_at_str}
              </Link>
            </Flex>
            {isEditing && (
              <div className={styles.commentForm}>
                <EditComment
                  commentId={id}
                  postId={post_id}
                  onCancel={() => setIsEditing(false)}
                />
              </div>
            )}
            {!isEditing && (
              <div className={styles.commentBody}>
                <FormattedMetadata text={body} metadata={metadata} />
                {external_images?.length > 0 && (
                  <PostImages
                    isComment={true}
                    metadata={metadata}
                    images={external_images}
                    post={{
                      body,
                      profile: { display_name, profile_image },
                      published_at_str,
                      published_at,
                    }}
                  />
                )}
              </div>
            )}

            <div className={styles.commentActions}>
              <CommentsActions
                permissions={permissions}
                onAction={handleAction}
                votesCount={vote?.vote_sum}
                activeVote={vote?.vote_type}
                counters={{
                  id,
                  votes_up,
                  votes_down,
                  reposts_count,
                  comments_count,
                }}
              />
            </div>
            {isCommentFormOpen && (
              <div className={styles.commentForm}>
                <WriteComment
                  defaultOpen={true}
                  autoFocus={true}
                  onSubmit={({ data }) => {
                    return handleAction("reply", data);
                  }}
                />
              </div>
            )}
          </Flex>
        </Flex>
      </div>
      {comments?.length > 0 && isExpanded && (
        <div className={styles.threadCommentsWrap}>
          <Comments
            comments={sortArrayWithPostDataToEnd(comments)}
            onAction={onAction}
            parentId={id}
            comments_left={comments_left}
          />
        </div>
      )}
      {isLoading && (
        <div style={{ marginTop: "16px" }}>
          <Spinner />
        </div>
      )}
      {!isLoading && isExpanded && comments_left > 0 && (
        <Flex className={styles.moreWrap} align="center">
          <div
            className={styles.btnExpand}
            onClick={() =>
              handleAction("preload_comments", { comment_last_id })
            }
          >
            <AddSquare size="20" color="#91919d" />
          </div>
          <div
            className={styles.threadMoreComments}
            onClick={() =>
              handleAction("preload_comments", { comment_last_id })
            }
          >
            Ще {comments_left} комментарів
          </div>
        </Flex>
      )}
      {error && <div>{error}</div>}
    </Flex>
  );
};

const memoizedThreadItem = memo(ThreadItem);

export { memoizedThreadItem as ThreadItem };

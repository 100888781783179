import { createApi } from "@reduxjs/toolkit/query/react";

import axiosBaseQuery from "./axios-base-query";

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["user", "posts"],
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});

import {
  useGetSelfProfileAttachmentsQuery,
  useUploadAttachmentMutation,
} from "@/features/api/user-api";
import {
  getPreSignedUrl,
  uploadToPresignedUrl,
} from "@/services/upload-service";
import cn from "@/utils/helpers/className";
import { Button, Flex, Upload } from "antd";
import { GalleryImport } from "iconsax-react";
import React, { useRef, useState } from "react";
import { ReactCrop } from "react-image-crop";

import { Spinner } from "../../../../ui/spinner";
import styles from "./change-avatar.module.scss";

const ChangeAvatarOrBackground = ({
  onSuccess,
  attachmentType,
  initialCropState,
  imageRatio,
  minHeight,
  imageDirection = "column",
}) => {
  const { data: prevPhotos, isFetching: isLoadingPrevPhotos } =
    useGetSelfProfileAttachmentsQuery({
      attachment_type: attachmentType,
    });

  const [uploadAttachment, { isLoading: isLoadingUpdatingAvatar }] =
    useUploadAttachmentMutation();

  const [cropState, setCropState] = useState(initialCropState);
  const [isLoadUploadedImage, setIsLoadUploadedImage] = useState(false);
  const [newImage, setNewImage] = useState();
  const croppedImageRef = useRef();

  const handleChange = async ({ file }) => {
    setIsLoadUploadedImage(true);
    const { url, fields } = await getPreSignedUrl(file.name);
    await uploadToPresignedUrl(url, file, fields, () => {});
    setIsLoadUploadedImage(false);
    setNewImage({ url: URL.createObjectURL(file), key: fields.key });
    setCropState(initialCropState);
  };

  const calculateCrop = ({ naturalHeight, offsetHeight, cropState }) => {
    const ratio = naturalHeight / offsetHeight;
    const { x, y, width, height } = cropState;
    return {
      x: x * ratio,
      y: y * ratio,
      width: width * ratio,
      height: height * ratio,
    };
  };

  const clearState = () => {
    setNewImage(null);
    setCropState(initialCropState);
  };

  const onSave = async () => {
    const crop = calculateCrop({
      naturalHeight: croppedImageRef.current.naturalHeight,
      offsetHeight: croppedImageRef.current.getBoundingClientRect().height,
      cropState,
    });

    await uploadAttachment({
      file: newImage.key,
      attachment_type: attachmentType,
      actions: { crop },
    });
    onSuccess?.();
    clearState();
  };

  const onCancelCrop = () => clearState();

  const onSelectPreviousAvatar = async (avatar) => {
    await uploadAttachment({ id: avatar.id });
    onSuccess?.();
    clearState();
  };

  const renderUploadButton = () => (
    <Flex vertical align="center" justify="center">
      {isLoadUploadedImage ? (
        <Spinner />
      ) : (
        <GalleryImport size="32" color="#2a2a2a" />
      )}
    </Flex>
  );

  const renderPreviousAvatars = () => (
    <div className={styles.avatarsWrapper}>
      <div
        className={cn(
          styles.previousAvatars,
          imageDirection === "row" ? styles.row : "",
        )}
      >
        <div
          className={cn(
            styles.previousAvatars,
            imageDirection === "row" ? styles.row : "",
          )}
        >
          <Upload
            className={imageDirection === "column" && styles.fullWidthUpload}
            disabled={isLoadUploadedImage}
            name="avatar"
            customRequest={handleChange}
            listType="picture-card"
            showUploadList={false}
            accept="image/*"
          >
            {renderUploadButton()}
          </Upload>
          {!isLoadingPrevPhotos &&
            prevPhotos?.attachments?.map((avatar) => (
              <div
                className={cn(
                  styles.uploadWrapper,
                  imageDirection === "column" ? styles.column : "",
                )}
                key={avatar.medium?.url}
                onClick={() => onSelectPreviousAvatar(avatar)}
              >
                <img src={avatar.medium?.url} alt="Previous Avatar" />
              </div>
            ))}
        </div>
        {isLoadingPrevPhotos && <Spinner />}
      </div>
    </div>
  );

  const renderCropImage = () => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ReactCrop
        className={styles.styledCrop}
        minHeight={minHeight}
        crop={cropState}
        onChange={(crop) => setCropState(crop)}
        onComplete={console.log}
        aspect={imageRatio}
      >
        <img ref={croppedImageRef} src={newImage.url} alt="New Avatar" />
      </ReactCrop>
    </div>
  );

  return (
    <Flex gap="middle" vertical>
      {newImage ? renderCropImage() : renderPreviousAvatars()}
      {newImage && (
        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.styledButton}
            size="large"
            onClick={onCancelCrop}
          >
            Відмінити
          </Button>
          <Button
            className={styles.styledButton}
            loading={isLoadingUpdatingAvatar}
            type="primary"
            size="large"
            onClick={onSave}
          >
            Зберегти
          </Button>
        </div>
      )}
    </Flex>
  );
};

export { ChangeAvatarOrBackground };

import { useGetOtherProfileQuery } from "@/features/api/user-api";
import ROUTES from "@/routing/routes";
import { Flex } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React from "react";
import { Link, generatePath } from "react-router-dom";

import { ProfileBackdrop } from "../../profile/profile-header/components/profile-backdrop";
import { Avatar } from "../../ui/avatar";
import { Spinner } from "../../ui/spinner";
import styles from "./popover-user.module.scss";

const PopoverUser = ({ slug }) => {
  const { data: profile, isFetching } = useGetOtherProfileQuery(slug);
  const profileAvatar = profile?.profile_image?.medium?.url;
  const coverImage = profile?.cover_image?.large?.url;
  console.log(coverImage);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {isFetching && <Spinner />}
      {!isFetching && profile && (
        <div className={styles.popover}>
          <ProfileBackdrop height={127} profileBg={coverImage} />
          <Link
            to={generatePath(ROUTES.otherProfile, {
              slag: profile?.username,
            })}
          >
            <Flex gap={8} className={styles.popoverContent}>
              <Avatar
                profile_avatar={profileAvatar}
                className={styles.avatar}
              />
              <Flex vertical>
                <div className={styles.popoverName}>
                  {profile?.display_name}
                </div>
                <div className={styles.popoverUsername}>
                  @{profile?.username}
                </div>
              </Flex>
              {/* for profile interactions */}
              {/* {selectedProfile?.username !== profile?.username && ( */}
              {/*   <Flex gap={8}> */}
              {/*     <OtherProfileActionsButton */}
              {/*       username={profile?.username} */}
              {/*       relation={profile?.relations} */}
              {/*     /> */}
              {/*   </Flex> */}
              {/* )} */}
            </Flex>
            {profile?.bio && (
              <div className={styles.popoverBio}>
                <Paragraph
                  className={styles.popoverBioText}
                  ellipsis={{ rows: 3 }}
                >
                  {profile?.bio}
                </Paragraph>
              </div>
            )}
          </Link>
        </div>
      )}
    </div>
  );
};

export default PopoverUser;

import { Button, Flex, Input, Space, Typography } from "antd";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import styles from "../create-account/create-account.module.scss";

const { Text } = Typography;

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const RemindForm = ({ onSubmit, error }) => {
  return (
    <>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          passwordonfirm: "",
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Це обов'язкове поле";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
            onSubmit(values);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Flex vertical gap={16}>
              <Space direction="vertical">
                <Input
                  size={"large"}
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <Text type={"danger"}>{errors.email}</Text>
                ) : null}
              </Space>
              {error && <Text type={"danger"}>{error}</Text>}
              <Button
                className={styles.formButton}
                size={"large"}
                type={"primary"}
                disabled={isSubmitting}
              >
                Нагадати пароль
              </Button>
            </Flex>
          </form>
        )}
      </Formik>
    </>
  );
};

export default RemindForm;

import { ReactComponent as GalleryIcon } from "../../../../../assets/icons/post/gallery.svg";
import styles from "./image-more.module.scss";

const ImageMore = ({ moreCount }) => {
  return (
    <div className={styles.imageMore}>
      <GalleryIcon />
      <div className={styles.imageMoreLabel}>{moreCount}</div>
    </div>
  );
};

export { ImageMore };

import img from "@/assets/images/no-posts.png";
import { Link, generatePath } from "react-router-dom";

import ROUTES from "../../../routing/routes";
import styles from "./profile-no-posts.module.scss";

const ProfileNoPosts = ({ username = "" }) => {
  return (
    <div className={styles.noPosts}>
      <div>
        <img src={img} alt="No posts" className={styles.noPostsImage} />
      </div>
      <div className={styles.noPostsTitle}>
        У користувача{" "}
        <Link
          to={generatePath(ROUTES.otherProfile, {
            slag: username,
          })}
        >
          @{username}
        </Link>{" "}
        ще немає постів
      </div>
    </div>
  );
};

export { ProfileNoPosts };

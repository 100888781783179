import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import image from "../../assets/images/socket.png";
import ROUTES from "../../routing/routes";
import styles from "./no-permission-page.module.scss";

const NoPermissionPage = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.errorNumber}>403</p>
      <p className={styles.title}>Доступ заборонено</p>
      <p className={styles.subTitle}>
        Доступ до запитуваної сторінки заборонено або у користувача немає прав
        на перегляд контенту.
      </p>

      <Link to={ROUTES.home}>
        <Button className={styles.mainButton} type="primary" size="large">
          На головну
        </Button>
      </Link>

      {/* <div> */}
      <img className={styles.image} src={image} alt="" />
      {/* </div> */}
    </div>
  );
};

export { NoPermissionPage };

import { Input } from "antd";
import React, { useState } from "react";

import { CommentForm } from "../../forms/comment-form";
import styles from "../../forms/comment-form/comment-form.module.scss";

const WriteComment = ({ autoFocus, onSubmit, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen || false);
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      {!isOpen && (
        <Input.TextArea
          onClick={() => setIsOpen(true)}
          onFocus={() => {
            setIsOpen(true);
            setIsActive(true);
          }}
          onBlur={() => setIsActive(false)}
          size={"middle"}
          autoSize={true}
          value={""}
          autoFocus={autoFocus}
          className={styles.commentForm}
          placeholder="Написати коментар"
        />
      )}
      {isOpen && (
        <CommentForm
          onSubmit={onSubmit}
          isEditing={false}
          isActive={isActive}
          setIsActive={setIsActive}
        />
      )}
    </>
  );
};

export { WriteComment };

import styles from "../../../../pages/feed/feed.module.scss";
import bannerTop from "../../../../assets/images/banner-top.png";
import React from "react";

const FeedBanner = () => {
  return (
    <div className={styles.banner}>
      <img src={bannerTop}
           alt="Vmist — платформа, яку ми будуємо разом для всіх, хто створює, читає та ділиться думками." />
    </div>
  )
}

export { FeedBanner };
import { Flex } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { PostComments } from "../../components/comments/post-comments";
import { PostActionModals } from "../../components/post-action-modals";
import { PostCard } from "../../components/post/post-card";
import { Spinner } from "../../components/ui/spinner";
import {
  useGetPostByIdQuery,
  useRemovePostMutation,
  useUpdateVoteOnSinglePostMutation,
} from "../../features/api/posts-api";
import { fetchPostComment } from "../../features/post/post-actions";
import { removePostFromState } from "../../features/posts/posts-slice";
import ROUTES from "../../routing/routes";
import styles from "./post-page.module.scss";

const PostPage = () => {
  const dispatch = useDispatch();
  const { id, username } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    type: null,
    data: null,
  });
  const {
    data: post,
    error: postError,
    isLoading,
  } = useGetPostByIdQuery({ id, username });
  const [removePost] = useRemovePostMutation();
  const [updateVote] = useUpdateVoteOnSinglePostMutation();

  const onAddNewThread = async ({ body, postId }) => {
    const res = await dispatch(fetchPostComment({ postId, body }));
    return res;
  };

  const onPostRemove = async () => {
    try {
      await removePost(post?.id);
      dispatch(removePostFromState(post?.id));
      navigate("/", {
        state: { scrollPos: location?.state?.scrollPos ?? undefined },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleActionWithId = ({ id, type, metadata }) => {
    if (type === "remove_request") {
      setModal({
        type: "remove_request",
        data: id,
      });
    } else if (type === "report") {
      setModal({
        type: "report",
        data: id,
      });
    } else if (type === "edit") {
      setModal({
        type: "edit",
        data: id,
      });
    } else {
      return onPostAction({ id, type, metadata });
    }
  };

  const onPostAction = ({ type, id: postId, metadata }) => {
    switch (type) {
      case "comment": {
        break;
      }
      case "reply": {
        if (metadata) {
          return onAddNewThread({ body: metadata, postId: postId });
        }
        break;
      }
      case "vote": {
        const { type } = metadata;
        updateVote({ postId: postId, voteType: type, seq_id: id, username });
        break;
      }
      case "remove": {
        onPostRemove();
      }
    }
  };

  if (postError?.status === 404) {
    return <Navigate to={ROUTES.notFoundPage} />;
  }

  if (postError?.status === 403) {
    return <Navigate to={ROUTES.noPermission} />;
  }

  const canViewPost = !isLoading && !postError;
  return (
    <div className={styles.pageWrap}>
      <PostActionModals
        type={modal?.type}
        onCancel={() => setModal({ type: null, data: null })}
        data={modal?.data}
        onSubmit={handleActionWithId}
      />
      <Flex className={styles.feedWrap} vertical gap={8}>
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <Spinner />
          </div>
        )}
        {canViewPost && (
          <PostCard
            post={post}
            onAction={handleActionWithId}
            hasCommentForm={!post?.flags?.is_locked}
          />
        )}
        {!post?.flags?.is_locked && (
          <PostComments id={post?.id} hidden={isLoading} />
        )}
      </Flex>
    </div>
  );
};

export { PostPage };

export const POST_ACTION_TYPES = {
  UP: "up",
  DOWN: "down",
  COMMENT: "comment",
  REPOST: "repost",
  REMOVE_REQUEST: "remove_request",
  REMOVE: "remove",
  EDIT: "edit",
  REPORT: "report",
};

import rightSidebarImage1 from "../../../assets/images/right-sidebar-1.png";
import styles from "./right-column.module.scss";

const RightColumn = () => {
  return (
    <div className={styles.column}>
      <div className={styles.content}>
        <div className={styles.widget}>
          <img src={rightSidebarImage1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default RightColumn;

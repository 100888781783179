import { ReactComponent as Loader } from "@/assets/icons/systems/loader-horizontal.svg";
import React from "react";

import styles from "./spinner.module.scss";

const Spinner = () => {
  return (
    <div className={styles.wrapper}>
      <Loader />
    </div>
  );
};

export { Spinner };

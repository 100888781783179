import { Button, Flex, Input, Space, Typography } from "antd";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import styles from "./settings.module.scss";

const { Text } = Typography;

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Settings = ({ onSubmit, error }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <h1 className={styles.title}>Налаштування</h1>
        <Formik
          initialValues={{
            displayname: "",
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Це обов'язкове поле";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Flex vertical gap={16}>
                <Space direction="vertical" className={styles.row}>
                  <div className={styles.subtitle}>Ім&apos;я та Прізвище</div>
                  <Input
                    size={"large"}
                    type="text"
                    name="displayname"
                    placeholder="Ім'я та Прізвище"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.displayname}
                  />
                  {errors.displayname && touched.displayname ? (
                    <Text type={"danger"}>{errors.displayname}</Text>
                  ) : null}
                </Space>

                <Space direction="vertical" className={styles.row}>
                  <div className={styles.subtitle}>Юзернейм</div>
                  <Input
                    size={"large"}
                    type="text"
                    name="username"
                    placeholder="Юзернейм"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                  />
                  {errors.username && touched.username ? (
                    <Text type={"danger"}>{errors.username}</Text>
                  ) : null}
                </Space>

                <Space direction="vertical" className={styles.row}>
                  <div className={styles.subtitle}>Email</div>
                  <Input
                    size={"large"}
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <Text type={"danger"}>{errors.email}</Text>
                  ) : null}
                </Space>

                <Space direction="vertical" className={styles.row}>
                  <div className={styles.subtitle}>Старий пароль</div>
                  <Input
                    size={"large"}
                    type="password"
                    name="password"
                    placeholder="Старий пароль"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    autoComplete="new-password"
                  />
                  {errors.password && touched.password ? (
                    <Text type={"danger"}>{errors.password}</Text>
                  ) : null}
                </Space>

                <Space direction="vertical" className={styles.row}>
                  <div className={styles.subtitle}>Новий пароль</div>
                  <Input
                    size={"large"}
                    type="password"
                    name="password-new"
                    placeholder="Новий пароль"
                  />
                </Space>

                <Space direction="vertical" className={styles.row}>
                  <div className={styles.subtitle}>Повторіть новий пароль</div>
                  <Input
                    size={"large"}
                    type="password"
                    name="password-new-again"
                    placeholder="Новий пароль ще раз"
                  />
                </Space>

                {error && <Text type={"danger"}>{error}</Text>}

                <Flex gap={16} justify="space-between">
                  <Button size={"large"} type={"default"}>
                    Скасувати
                  </Button>
                  <Button
                    className={styles.formButton}
                    size={"large"}
                    type={"primary"}
                    disabled={isSubmitting}
                  >
                    Зберегти
                  </Button>
                </Flex>
              </Flex>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export { Settings };

import React from "react";
import { Link, generatePath } from "react-router-dom";

import ROUTES from "../../routing/routes";
import { CreditCard } from "../ui/metadata-previews/credit-card";
import { LinkEmail } from "../ui/metadata-previews/link-email";
import { LinkNumber } from "../ui/metadata-previews/link-number";
import { LinkPreview } from "../ui/metadata-previews/link-preview";
import { ProfileInfoTooltip } from "../user-popover";

const MyComponentReplacement = ({ metadataItem }) => {
  switch (metadataItem?.metadata_type) {
    case "phone_number": {
      return <LinkNumber number={metadataItem.value} />;
    }
    case "cc_number": {
      return (
        <CreditCard
          value={metadataItem.value}
          provider={metadataItem.provider}
        />
      );
    }
    case "youtube_video":
    case "url_preview": {
      return (
        <LinkPreview
          type={metadataItem?.metadata_type}
          url={metadataItem.url}
          title={metadataItem.title}
          label={metadataItem.value}
          description={metadataItem.description}
          external_image={metadataItem.external_image}
        />
      );
    }
    case "mention": {
      return (
        <ProfileInfoTooltip username={metadataItem.profile?.username}>
          <Link
            to={generatePath(ROUTES.otherProfile, {
              slag: metadataItem.profile?.username,
            })}
          >
            {metadataItem.value}
          </Link>
        </ProfileInfoTooltip>
      );
    }
    case "email": {
      return <LinkEmail email={metadataItem?.value} />;
    }
    default:
      return null;
  }
};

function replacePlaceholdersWithComponent(
  text,
  ReplacementComponent,
  metadata,
  oneLine,
) {
  return text
    ?.replace(/\n/g, oneLine ? " " : "\n")
    ?.split(/(\{\d+\})/g) // Remove \u200B and split by the remaining placeholder pattern
    ?.map((part, index) => {
      const match = part.match(/\{(\d+)\}/);
      if (match) {
        const id = parseInt(match[1], 10);
        const metadataItem = metadata.find((item) => item.id === id);
        return <ReplacementComponent key={index} metadataItem={metadataItem} />;
      }
      return part;
    });
}

const FormattedMetadata = ({ metadata, text, oneLine }) => {
  // Wrap output in a single div/span so Typography can handle ellipsis
  return (
    <>
      {replacePlaceholdersWithComponent(
        text,
        MyComponentReplacement,
        metadata,
        oneLine,
      )}
    </>
  );
};

export { FormattedMetadata };

import { Button, Flex, Tooltip } from "antd";
import { Lock1, Unlock } from "iconsax-react";
import React from "react";

import * as vars from "../../../utils/constants/variables";
import cn from "../../../utils/helpers/className";
import SvgIcon from "../svg-icon/svg-icon";
import styles from "./post-form-footer.module.scss";

const PostFormFooter = ({
  onUploadClick,
  isLocked,
  onLockToggle,
  onCancel,
  isSubmitDisabled,
}) => (
  <div className={styles.wrapper}>
    <Flex className={styles.settings} align="center">
      <Button
        onClick={onUploadClick}
        type="default"
        size="large"
        className={styles.button}
      >
        <SvgIcon
          className={cn(styles.icon, styles.paperClip)}
          name={"paper-clip"}
          width={20}
          height={20}
        />
      </Button>
      <Button
        onClick={onLockToggle}
        type={isLocked ? "primary" : "default"}
        size="large"
        className={
          isLocked ? cn(styles.button, styles.buttonPrimary) : styles.button
        }
      >
        {isLocked ? (
          <Lock1 size="20" color={"#FFFFFF"} width={20} />
        ) : (
          <Unlock size="20" color={vars.colors.grey} />
        )}
      </Button>
    </Flex>
    <div className={styles.buttons}>
      <Button
        className={styles.item}
        type="default"
        size="large"
        onClick={onCancel}
      >
        Закрити
      </Button>
      <Tooltip title="Ctrl + Enter">
        <Button
          className={styles.item}
          disabled={isSubmitDisabled}
          type="primary"
          size="large"
          htmlType={"submit"}
        >
          Опублікувати
        </Button>
      </Tooltip>
    </div>
  </div>
);

export { PostFormFooter };

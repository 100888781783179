import { Menu } from "antd";
import { ArchiveTick, Profile2User, Ranking, Refresh } from "iconsax-react";
import React, { useMemo } from "react";

import { useGetSelfProfileState } from "../../../features/api/user-api";
import styles from "./aside-nav.module.scss";

const AsideNav = ({ onSelect, selectedCategory }) => {
  const { data: profile } = useGetSelfProfileState();

  const items = useMemo(
    () => [
      {
        key: "feed",
        icon: <Profile2User size={16} />,
        label: "Підписки",
        disabled: !profile?.username,
      },
      {
        key: "fresh",
        icon: <Refresh size={16} />,
        label: "Свіже",
      },
      {
        key: "3",
        icon: <Ranking size={16} />,
        label: "Актуальне",
        disabled: true,
      },
      {
        key: "sub1",
        label: "Теми",
        icon: <ArchiveTick size={16} />,
        children: [
          {
            key: "5",
            label: "Збори",
            disabled: true,
          },
          {
            key: "6",
            label: "Новини",
            disabled: true,
          },
          {
            key: "7",
            label: "Політика",
            disabled: true,
          },
          {
            key: "8",
            label: "Кіно",
            disabled: true,
          },
          {
            key: "9",
            label: "Музика",
            disabled: true,
          },
          {
            key: "10",
            label: "Спорт",
            disabled: true,
          },
          {
            key: "11",
            label: "Меми",
            disabled: true,
          },
        ],
      },
    ],
    [profile],
  );

  const onCategorySelect = ({ key }) => {
    onSelect(key);
  };

  return (
    <div style={{ width: 162 }}>
      <Menu
        selectedKeys={[selectedCategory]}
        onClick={onCategorySelect}
        className={styles.menu}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="light"
        inlineIndent={20}
        items={items}
      />
    </div>
  );
};

export default AsideNav;

import { Typography } from "antd";

import { cardProviders } from "../../../../utils/constants/options";
import styles from "./credit-card.module.scss";

const { Text } = Typography;

const CreditCard = ({ value, provider }) => {
  return (
    <Text
      className={styles.creditCard}
      copyable={{
        text: value,
        icon: [value, value],
        tooltips: ['Скопіювати', 'Скопійовано'],
      }}
    >
      <span className={styles.provider}>{cardProviders[provider] || ""}</span>
    </Text>
  );
};

export { CreditCard };

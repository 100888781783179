import { ReactComponent as GoogleIcon } from "@/assets/icons/social/google.svg";
import { useGoogleLogin } from "@react-oauth/google";
import React from "react";

import { signInByGoogle } from "../../../services/oauth-service";
import { save } from "../../../utils/helpers/local-storage";
import { SocialLoginButton } from "../../ui/social-login-button";

const GoogleAuth = () => {
  const responseMessage = async (data) => {
    try {
      const token = await signInByGoogle(data.access_token);
      if (token?.headers?.authorization && token?.headers?.["refresh-token"]) {
        save("accessToken", token?.headers?.authorization);
        save("refreshToken", token?.headers?.["refresh-token"]);
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  const login = useGoogleLogin({
    flow: "implicit",
    onSuccess: responseMessage,
    onError: errorMessage,
    scope: "email profile openid",
  });
  return <SocialLoginButton onClick={login} icon={<GoogleIcon />} />;
};

export { GoogleAuth };

import React from "react";
import { Helmet } from "react-helmet";

import { PostList } from "../../components/post/posts-list";
import { EmptyFeed } from "../../components/ui/empty-feed";
import { Spinner } from "../../components/ui/spinner";
import useFeedPosts from "../../hooks/use-feed-posts";
import styles from "./feed.module.scss";
import { FeedBanner } from "../../components/ui/banners/feed-banner";
import { useGetSelfProfileState } from "../../features/api/user-api";



const Feed = () => {
  const { data: profile } = useGetSelfProfileState();
  const {
    posts,
    error,
    onPostAction,
    getPosts,
    hasMorePosts,
    isFetchingNextPage,
    isLoading,
  } = useFeedPosts();
  return (
    <>
      <Helmet>
        <title>Усі дописи · Вміст</title>
      </Helmet>
      <div className={styles.feedWrap}>
        {!profile?.username && !isLoading && <FeedBanner />}
        <PostList
          posts={posts}
          onAction={onPostAction}
          getPosts={getPosts}
          hasMorePosts={hasMorePosts}
        />
        {isFetchingNextPage || (isLoading && <Spinner />)}
        {!isLoading && !posts?.length && <EmptyFeed />}
        {error?.length && <div>{error}</div>}
      </div>
    </>
  );
};

export { Feed };

import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../../ui/header";
import styles from "./clean-layout.module.scss";

const CleanLayout = () => {
  return (
    <div className={styles.wrap}>
      <Header />
      <div className={styles.column}>
        <Outlet />
      </div>
    </div>
  );
};

export default CleanLayout;
import Sider from "antd/es/layout/Sider";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate, useParams } from "react-router-dom";

import { useGetSelfProfileState } from "../../../features/api/user-api";
import {
  resetFeedPosts,
  setSearchPostsQuery,
} from "../../../features/posts/posts-slice";
import ROUTES from "../../../routing/routes";
import { NewPostModal } from "../../post/new-post-modal/new-post-modal";
import AsideLinks from "../aside-links";
import AsideNav from "../aside-nav";
import styles from "./aside.module.scss";

export function getCategory(searchQuery, profile, isFeedPage, type) {
  if (!isFeedPage || searchQuery) return "";
  if (!profile?.username) return "fresh";
  return type || "feed";
}

const Aside = ({ open, closeSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: profile } = useGetSelfProfileState();
  const isFeedPage = useMatch(ROUTES.feed);
  //modified for not logged in user
  const { type } = useParams();

  const searchValue = useSelector((state) => state.posts.searchPostsQuery);
  const onSelectCategory = (category) => {
    if (
      getCategory(searchValue.query, profile, isFeedPage, type) !== category
    ) {
      if (isMobile) {
        closeSidebar();
      }
      navigate(category === "feed" ? "feed" : "feed" + `/${category}`);
      dispatch(resetFeedPosts());
      dispatch(
        setSearchPostsQuery({
          page: null,
          query: null,
          displayValue: "",
        }),
      );
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Sider
      theme={"custom"}
      className={styles.siderCustom}
      width="180"
      breakpoint="xl"
      collapsedWidth="0"
      collapsed={false}
      trigger={null}
    >
      <div className={styles.siderColumn}>
        <NewPostModal />
        <div className={styles.siderMenus}>
          <AsideNav
            onSelect={onSelectCategory}
            selectedCategory={getCategory(
              searchValue.query,
              profile,
              isFeedPage,
              type,
            )}
          />
          <AsideLinks />
        </div>
      </div>
    </Sider>
  );
};

export default Aside;

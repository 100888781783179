import { Button, Modal } from "antd";
import { Youtube } from "iconsax-react";
import { useState } from "react";
import { Link } from "react-router-dom";

import styles from "./link-mobile-preview.module.scss";

export const LinkMobilePreview = ({
  url,
  title,
  description,
  external_image,
  type,
  label,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <span className={styles.link} onClick={showModal}>
        {type === "youtube_video" && (
          <span className={styles.linkImg}>
            <Youtube size="18" color="#FF8A65" variant="Bold" />
          </span>
        )}
        {label}
      </span>
      <Modal
        title={null}
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        className={styles.linkPreviewModal}
        width={"100%"}
        transitionName=""
        closable={true}
      >
        {external_image && (
          <div className={styles.modalImage}>
            <img src={external_image} alt={title} />
          </div>
        )}
        <div className={styles.modalContent}>
          <div className={styles.modalTitle}>{title}</div>
          <div className={styles.modalDescription}>{description}</div>
        </div>
        <div className={styles.modalControls}>
          <Button
            size="large"
            className={styles.modalButton}
            onClick={handleCancel}
          >
            Назад
          </Button>
          <Button
            as={Link}
            type="primary"
            size="large"
            className={styles.modalButton}
            href={url}
            target="_blank"
            onClick={handleCancel}
          >
            Перейти
          </Button>
        </div>
      </Modal>
    </>
  );
};

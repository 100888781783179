import { ReactComponent as Upvote } from "@/assets/icons/reactions/upvote.svg";
import { votesActions } from "@/utils/constants/options";
import { Flex } from "antd";
import React, { useRef } from "react";
import { isMobile } from "react-device-detect";
import { useDebouncedCallback } from "use-debounce";

import styles from "./votes.module.scss";

const canVibrate = window.navigator.vibrate;
const Votes = ({ onVote, votesCount, activeVote }) => {
  const [open, setOpen] = React.useState(false);

  const ref = useRef(null);

  const debouncedOpen = useDebouncedCallback(() => {
    setOpen(true);

    if (canVibrate && !open) {
      window.navigator.vibrate([50]);
    }
  }, 600);

  const wait = isMobile ? 3000 : 0;

  const handlOnMouseLeave = useDebouncedCallback(() => {
    setOpen(false);
    debouncedOpen?.cancel();
  }, wait);

  const onFastVote = (vote) => {
    if (!open && !activeVote) {
      onVote(vote);
    }
    if (!open && activeVote) {
      onVote("");
    }
    if (canVibrate) {
      window.navigator.vibrate([50]);
    }
    debouncedOpen?.cancel();
  };

  const onOpenModalVote = (type) => {
    if (activeVote === type) {
      onVote("");
    } else {
      onVote(type);
    }
    if (canVibrate) {
      window.navigator.vibrate([50]);
    }
    setOpen(false);
  };

  const onOpenRoasterVote = (e, vote) => {
    e.preventDefault();
    e.stopPropagation();
    onOpenModalVote(vote.type);
    if (isMobile) {
      setOpen(false);
      debouncedOpen?.cancel();
    }
  };

  return (
    <Flex
      className={styles.actionButton}
      style={{
        ...(!open && {
          backgroundColor: votesActions.find((vote) => vote.type === activeVote)
            ?.bgColor,
        }),
      }}
    >
      <div
        className={styles.wrapper}
        ref={ref}
        onClick={() => onFastVote("upvote")}
        onPointerEnter={debouncedOpen}
        onPointerLeave={handlOnMouseLeave}
      >
        {!activeVote && !open && (
          <div className={styles.actionMainIcon}>
            <Upvote />
          </div>
        )}
        {!open && activeVote && (
          <div
            className={styles.actionIcon}
            style={{
              backgroundColor: votesActions.find(
                (vote) => vote.type === activeVote,
              )?.btnColor,
            }}
          >
            {votesActions.find((vote) => vote.type === activeVote)?.iconActive}
          </div>
        )}
        {open &&
          votesActions.map((vote) => (
            <div
              className={styles.actionIcon}
              key={vote.type}
              onClick={(e) => onOpenRoasterVote(e, vote)}
            >
              {vote.icon}
            </div>
          ))}
      </div>
      {!open && (
        <div
          className={styles.actionLabel}
          style={{
            color: votesActions.find((vote) => vote.type === activeVote)
              ?.lblColor,
          }}
        >
          {votesCount}
        </div>
      )}
    </Flex>
  );
};

export default Votes;

import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import image from "../../assets/images/facepalm-man.png";
import ROUTES from "../../routing/routes";
import styles from "./not-found-page.module.scss";

const NotFoundPage = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.errorNumber}>404</p>
      <p className={styles.title}>Сторінка не знайдена</p>
      <p className={styles.subTitle}>
        Ой, ми не можемо знайти сторінку, яку ви шукаєте. Спробуй повернутися на
        попередню сторінку.
      </p>

      <Link to={ROUTES.home}>
        <Button className={styles.mainButton} type="primary" size="large">
          На головну
        </Button>
      </Link>

      <div className={styles.manImage}>
        <img className={styles.image} src={image} alt="" />
      </div>
    </div>
  );
};

export { NotFoundPage };

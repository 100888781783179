import { Input, notification } from "antd";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";

import useEnterSubmit from "../../../hooks/use-enter-submit";
import usePasteHandler from "../../../hooks/use-paste-handler";
import {
  getPreSignedUrl,
  uploadToPresignedUrl,
} from "../../../services/upload-service";
import { MAX_POST_IMAGES_COUNT } from "../../../utils/constants/integers";
import cn from "../../../utils/helpers/className.js";
import { getAllErrors } from "../../../utils/helpers/string-transform";
import { AttachmentsList } from "../../ui/attachments-list";
import { DragAndDropWrapper } from "../../ui/drag-and-drop-wrapper";
import { PostFormFooter } from "../../ui/post-form-footer";
import styles from "./post-form.module.scss";

const PostForm = ({ onCancel, post, onSubmit }) => {
  const [attachments, setAttachments] = React.useState([]);
  const uploadInputRef = React.useRef(null);
  const [notificationApi, contextHolder] = notification.useNotification();
  const formRef = useRef(null);

  const onUploadPercentChange = (id, progress) => {
    setAttachments((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            process: progress,
          };
        }
        return item;
      });
    });
  };

  const createAttachmentStateItem = async (file) => {
    try {
      const name = file.name;
      const { url, fields } = await getPreSignedUrl(name);
      setAttachments((prev) => [
        ...prev,
        {
          id: fields.key,
          file,
          process: 0,
        },
      ]);

      uploadToPresignedUrl(url, file, fields, (progress) =>
        onUploadPercentChange(fields.key, progress),
      );
    } catch (e) {
      getAllErrors(e.response.data).forEach((item) => {
        notificationApi.error({
          message: item,
        });
      });
    }
  };

  const onAddFile = async (e) => {
    const files = e.target.files;
    if (attachments.length + files.length > MAX_POST_IMAGES_COUNT) {
      notificationApi.error({
        message: "Один пост може містити не більше 20 файлів",
      });
      return;
    }
    for await (const file of files) {
      await createAttachmentStateItem(file);
    }
    e.target.value = null;
  };

  const onUploadClick = () => {
    uploadInputRef.current.click();
  };

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      ...values,
      files: attachments.map((item) => item.id),
    };
    await onSubmit({ data })
      .then((res) => {
        if (!res.payload?.errors?.length) {
          resetForm();
          setAttachments([]);
        } else {
          res.payload?.errors.forEach((item) => {
            notificationApi.error({
              message: item,
            });
          });
        }
      })
      .catch((error) => {
        getAllErrors(error).forEach((item) => {
          notificationApi.error({
            message: item,
          });
        });
      });
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    let handled = false;

    for (const item of items) {
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) {
          createAttachmentStateItem(file);
          handled = true;
        }
      }
    }

    if (!handled) {
      const pastedText = event.clipboardData.getData("text/plain");
      if (pastedText && !formRef?.current?.values?.body) {
        formRef.current.setFieldValue("body", pastedText);
        event.preventDefault();
      }
    }
  };

  usePasteHandler(handlePaste, formRef);
  useEnterSubmit(() => formRef.current?.submitForm());

  useEffect(() => {
    if (post?.external_images?.length) {
      setAttachments(post?.external_images);
    }
  }, [post]);

  return (
    <>
      {contextHolder}
      <DragAndDropWrapper onDropFile={createAttachmentStateItem}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            body: post?.body || "",
            is_locked: post?.is_locked || false,
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div
                className={cn(
                  styles.formPostWrap,
                  !attachments.length && styles.noAttachments,
                )}
              >
                <Field
                  name="body"
                  render={({ field }) => (
                    <Input.TextArea
                      className={styles.formTextarea}
                      {...field}
                      autoSize={true}
                      placeholder="Створіть новий допис"
                    />
                  )}
                />
                <div>
                  <input
                    ref={uploadInputRef}
                    type="file"
                    onChange={onAddFile}
                    multiple
                    style={{ display: "none" }}
                  />
                  <AttachmentsList
                    attachments={attachments}
                    onRemoveAttachment={(image) => {
                      setAttachments((prev) =>
                        prev.filter((item) => item.id !== image),
                      );
                    }}
                  />
                </div>
              </div>
              <PostFormFooter
                onCancel={onCancel}
                isLocked={values.is_locked}
                onUploadClick={onUploadClick}
                isSubmitDisabled={
                  !values.body.trim() && attachments.length === 0
                }
                onLockToggle={() =>
                  setFieldValue("is_locked", !values.is_locked)
                }
              />
            </Form>
          )}
        </Formik>
      </DragAndDropWrapper>
    </>
  );
};

export default PostForm;

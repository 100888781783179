import defaultProfileAvatar from "@/assets/images/avatar.svg";

const Avatar = ({ profile_avatar, className, style, ...props }) => {
  return (
    <img
      src={profile_avatar || defaultProfileAvatar}
      alt="profile avatar"
      className={className}
      style={style}
      {...props}
    />
  );
};

export { Avatar };

import { Flex } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, useParams } from "react-router-dom";

import { PostList } from "../../components/post/posts-list";
import { FullscreenAvatars } from "../../components/profile/fullscreen-avatars";
import { OtherProfileActionsButton } from "../../components/profile/other-profile-actions-button";
import { ProfileBio } from "../../components/profile/profile-bio";
import ProfileHeader from "../../components/profile/profile-header";
import { ProfileNoPosts } from "../../components/profile/profile-no-posts";
import { AskToUnblockContainer } from "../../components/ui/ask-to-unblock-container";
import { BlockedContainer } from "../../components/ui/blocked-container";
import { Spinner } from "../../components/ui/spinner";
import {
  useGetOtherProfileQuery,
  useGetSelfProfileState,
} from "../../features/api/user-api";
import useOtherProfilePosts from "../../hooks/use-other-profile-posts";
import ROUTES from "../../routing/routes";
import styles from "./other-profile.module.scss";

const OtherProfile = () => {
  const params = useParams();
  const [fullScreenOpenedImage, setFullScreenOpenedImage] = useState(null);

  const {
    data: profileData,
    isFetching: isProfileLoading,
    error: profileError,
  } = useGetOtherProfileQuery(params?.slag, {
    skip: !params?.slag,
  });
  const { data: selfProfileData } = useGetSelfProfileState();

  const {
    posts,
    error,
    isLoading,
    onPostAction,
    getPosts,
    hasMorePosts,
    isFetchingNextPage,
  } = useOtherProfilePosts({
    skip:
      !profileData?.id ||
      !selfProfileData?.username ||
      selfProfileData?.username !== params?.slag,
    username: profileData?.username,
  });

  const onAvatarClick = async () => {
    if (profileData?.profile_image?.large?.url) {
      setFullScreenOpenedImage({
        index: 0,
        image: profileData?.profile_image?.large?.url,
      });
    }
  };

  const isBlocked = profileData?.relations?.block;
  const isBlockedMe = profileData?.relations?.blocked_me;
  const hasPosts = posts?.length > 0;
  const isCanAskForUnblock = profileData?.permissions?.can_ask_for_unblock;
  if (profileError?.status === 404) {
    return <Navigate to={ROUTES.notFoundPage} />;
  }

  if (profileError?.status === 403) {
    return <Navigate to={ROUTES.noPermission} />;
  }

  if (isProfileLoading) {
    return <Spinner />;
  }

  if (profileData?.relations?.self) {
    return <Navigate to={ROUTES.profile} replace />;
  }

  return (
    <>
      <Helmet>
        {profileData?.display_name && (
          <title>
            {profileData?.display_name + " · @" + profileData?.username}
          </title>
        )}
        <meta name="description" content={profileData?.bio} />
      </Helmet>
      {fullScreenOpenedImage && (
        <FullscreenAvatars
          onAttachmentEvent={setFullScreenOpenedImage}
          startIndex={fullScreenOpenedImage.index}
          username={profileData?.username}
        />
      )}
      <Flex vertical className={styles.column}>
        <ProfileHeader
          onAvatarClick={onAvatarClick}
          profile={profileData}
          isLoading={isProfileLoading}
          bioComponent={
            <ProfileBio isEditing={false} value={profileData?.bio} />
          }
          topRightComponent={
            <>
              <OtherProfileActionsButton
                username={profileData?.username}
                relation={profileData?.relations}
                permissions={profileData?.permissions}
              />
            </>
          }
        />
        <div>
          {isBlockedMe && <AskToUnblockContainer />}
          {isBlocked && <BlockedContainer />}
          {!isBlocked && !isCanAskForUnblock && (
            <>
              <PostList
                posts={posts}
                onAction={onPostAction}
                getPosts={getPosts}
                hasMorePosts={hasMorePosts}
              />
              {!hasPosts && !isLoading && (
                <ProfileNoPosts username={profileData?.username} />
              )}
              {isFetchingNextPage && <Spinner />}
              {error?.length && <div>{error}</div>}
            </>
          )}
        </div>
      </Flex>
    </>
  );
};

export { OtherProfile };

import { Skeleton } from "antd";
import React from "react";

import {
  IMAGE_GRID_COUNT,
  IMAGE_GRID_MORE_COUNT,
} from "../../../utils/constants/integers";
import cn from "../../../utils/helpers/className";
import { ImageMore } from "./components/image-more";
import styles from "./image-grid.module.scss";

const ImageGrid = ({ images, onImageClick, isComment }) => {
  const hasInvisibleImages = images.length > IMAGE_GRID_MORE_COUNT;
  const visibleImages =
    images?.length > 0 ? images.slice(0, IMAGE_GRID_COUNT) : [];

  return (
    <div className={styles.gridContainer}>
      {visibleImages?.length > 0 &&
        visibleImages.map((image, index) => {
          return (
            <div
              className={cn(
                styles.imageWrapper,
                isComment ? styles.imageSingle : "",
              )}
              key={index}
              onClick={() =>
                image?.large?.url &&
                image?.medium?.url &&
                onImageClick({ index, image })
              }
            >
              {image?.medium?.url && (
                <img
                  className={styles.image}
                  loading="lazy"
                  src={image?.medium?.url}
                  alt={`Image ${index}`}
                />
              )}
              {hasInvisibleImages && index === 1 && (
                <ImageMore moreCount={images.length} />
              )}
              {!image?.medium?.url && <Skeleton.Image active></Skeleton.Image>}
            </div>
          );
        })}
    </div>
  );
};

export { ImageGrid };

import { Flex } from "antd";
import { Link } from "react-router-dom";

import styles from "./aside-links.module.scss";
import ROUTES from "../../../routing/routes";

const AsideLinks = () => {
  return (
    <div className={styles.list}>
      <Flex vertical gap={12}>
        <a href="mailto:admin@vmist.net" className={styles.link}>
          Повідомити про баг
        </a>
        <Link to={ROUTES.rules} target="_blank" className={styles.link}>
          Правила
        </Link>
      </Flex>
    </div>
  );
};

export default AsideLinks;

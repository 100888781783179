import axios from "axios";

export const signInByGoogle = async (token) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}users/oauth2/google`,
    { token },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

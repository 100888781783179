import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchCommentVote,
  fetchComments,
  fetchNextThreads,
  fetchPostComment,
  fetchRemoveComment,
  fetchThreadsForPost,
  refetchOneComment,
} from "../../../features/post/post-actions";
import { useCable } from "../../../hooks/useCable";

export const usePostComments = ({ id }) => {
  const cable = useCable();
  const dispatch = useDispatch();
  const {
    data,
    isLoading,
    comment_last_id,
    isLoadingNext,
    comments_left,
    error,
  } = useSelector((state) => state.post.comments);

  const getComments = () => {
    dispatch(fetchNextThreads({ id, from_id: comment_last_id }));
  };

  const onAddNewThread = async ({ body, postId }) => {
    const res = await dispatch(fetchPostComment({ postId, body }));
    return res;
  };

  const onAddCommentToThread = async ({ postId, body, commentId }) => {
    const res = await dispatch(
      fetchPostComment({
        postId,
        body,
        comment_id: commentId,
      }),
    );
    return res;
  };

  const handleLoadNextThreadComments = ({ fromId, postId, parentId }) => {
    dispatch(
      fetchComments({
        from_id: fromId,
        post_id: postId,
        parent_id: parentId,
      }),
    );
  };

  const handleCommentRemove = async ({ comment_id, thread_id, post_id }) => {
    const res = await dispatch(
      fetchRemoveComment({ comment_id, thread_id, post_id }),
    );
    return res;
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchThreadsForPost({ id }));
      cable?.subscriptions?.create(
        {
          channel: "CommentChannel",
          post_id: id,
        },
        {
          received: (data) => {
            switch (data.event) {
              case "published":
                dispatch(
                  refetchOneComment({
                    comment_id: data.comment_id,
                    post_id: id,
                    thread_id: data.thread_id,
                  }),
                );
                break;
              default:
                break;
            }
          },
        },
      );
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      cable?.subscriptions?.create(
        {
          channel: "CommentChannel",
          post_id: id,
        },
        {
          received: (data) => {
            switch (data.event) {
              case "published":
                dispatch(
                  refetchOneComment({
                    comment_id: data.comment_id,
                    post_id: id,
                    thread_id: data.thread_id,
                  }),
                );
                break;
              default:
                break;
            }
          },
        },
      );
    }
  }, [cable]);

  return {
    threadLastId: comment_last_id,
    data,
    isLoading: isLoading,
    isLoadingNext,
    error,
    commentsLeft: comments_left,
    getComments,
    onAddNewThread,
    onAddCommentToThread,
    handleLoadNextThreadComments,
    handleCommentRemove,
    fetchCommentVote: (...data) => dispatch(fetchCommentVote(...data)),
    fetchRemoveComment: (...data) => dispatch(fetchRemoveComment(...data)),
  };
};

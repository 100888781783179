import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchUpdateComment } from "../../../features/post/post-actions";
import { getCommentForEdit } from "../../../services/comment-service";
import { CommentForm } from "../../forms/comment-form";

const EditComment = ({ onCancel, commentId, postId }) => {
  const dispatch = useDispatch();
  const [originalPost, setOriginalPost] = useState({});

  const getOriginalComment = async () => {
    const res = await getCommentForEdit(commentId, postId);
    setOriginalPost(res);
  };

  useEffect(() => {
    if (commentId) {
      getOriginalComment();
    }
  }, [commentId]);

  const handleSave = async ({ data }) => {
    const result = await dispatch(
      fetchUpdateComment({
        commentId,
        postId,
        postData: data,
      }),
    );
    if (result?.meta?.requestStatus === "fulfilled") {
      onCancel();
    }
  };

  return (
    <CommentForm
      comment={originalPost?.data}
      onSubmit={handleSave}
      isEditing={true}
      isActive={true}
      onCancel={onCancel}
    />
  );
};

export { EditComment };

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";

import { useGetSelfProfileState } from "../features/api/user-api";
import {
  fetchNextPosts,
  fetchPosts,
  fetchRemovePost,
  fetchUpdateVote,
} from "../features/posts/posts-actions";
import {
  isFetchingNextProfilePosts,
  resetProfilePosts,
  selectHasMoreProfilePosts,
  selectProfileError,
  selectProfileIsLoading,
  selectProfilePostLastId,
  selectProfilePosts,
} from "../features/posts/posts-slice";
import ROUTES from "../routing/routes";

const useProfilePosts = ({ username }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const posts = useSelector(selectProfilePosts);
  const post_last_id = useSelector(selectProfilePostLastId);
  const hasMorePosts = useSelector(selectHasMoreProfilePosts);
  const isFetchingNextPage = useSelector(isFetchingNextProfilePosts);
  const error = useSelector(selectProfileError);
  const isLoading = useSelector(selectProfileIsLoading);
  const { data: profile } = useGetSelfProfileState();
  const searchValue = useSelector((state) => state.posts.searchPostsQuery);

  useEffect(() => {
    if (username && !posts.length && !isLoading) {
      dispatch(
        fetchPosts({
          from_id: null,
          source: "profilePosts",
          username,
        }),
      );
    }
  }, [username, profile]);

  useEffect(() => {
    if (
      username &&
      searchValue?.query !== null &&
      searchValue?.page === "profilePosts"
    ) {
      dispatch(resetProfilePosts());
      dispatch(
        fetchPosts({
          from_id: null,
          source: "profilePosts",
          ...(searchValue?.query && { q: searchValue?.query }),
          username,
        }),
      );
    }
  }, [searchValue]);

  const getNextPosts = () => {
    if (post_last_id) {
      dispatch(
        fetchNextPosts({
          from_id: post_last_id,
          source: "profilePosts",
          username,
          ...(searchValue?.query && { q: searchValue?.query }),
        }),
      );
    }
  };

  const onComment = ({ id, username }) => {
    navigate(generatePath(ROUTES.post, { id, username: username }), {
      state: { scrollPos: window.scrollY },
    });
  };

  const onPostAction = useCallback(
    ({ id, type, metadata }) => {
      switch (type) {
        case "comment": {
          onComment({ id: metadata.seq_id, username: metadata.username });
          break;
        }
        case "vote": {
          const { type: voteType } = metadata;
          dispatch(fetchUpdateVote({ postId: id, voteType }));
          break;
        }
        case "remove": {
          dispatch(fetchRemovePost({ postId: id }));
          break;
        }
        default:
          break;
      }
    },
    [dispatch],
  );

  return {
    posts,
    isLoading,
    error,
    onPostAction,
    getPosts: getNextPosts,
    hasMorePosts: hasMorePosts,
    isFetchingNextPage,
  };
};

export default useProfilePosts;

import React from "react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  generatePath,
} from "react-router-dom";

import DefaultLayout from "../components/layouts/default";
import CleanLayout from "../components/layouts/clean";
import { ScrollRestorationComponent } from "../components/scroll-restoration";
import { ErrorPage } from "../pages/error";
import { Feed } from "../pages/feed";
import { NoPermissionPage } from "../pages/no-permission-page";
import { NotFoundPage } from "../pages/not-found-page";
import { OtherProfile } from "../pages/other-profile";
import { PostPage } from "../pages/post-page";
import { Profile } from "../pages/profile";
import { Settings } from "../pages/settings";
import AssignedUserRoute from "./AssignedUserRoute";
import { PrivacyPolicy } from "../pages/privacy-policy";
import { Rules } from "../pages/rules";
import ROUTES from "./routes";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTES.home} element={<ScrollRestorationComponent />}>
      {/* Unassigned users can view global feed and post by id */}
      <Route element={<DefaultLayout />}>
        <Route
          index
          element={<Navigate to={generatePath(ROUTES.feed, { type: "" })} />}
        />
        <Route path={ROUTES.feed} element={<Feed />} />
        <Route path={ROUTES.post} element={<PostPage />} />
        {/* Routes only for assigned users */}
        <Route element={<AssignedUserRoute />}>
          <Route path={ROUTES.profile} element={<Profile />} />
          <Route path={ROUTES.settings} element={<Settings />} />
        </Route>
        <Route path=":slag" element={<OtherProfile />} />
      </Route>

      <Route path={ROUTES.notFoundPage} element={<NotFoundPage />} />
      <Route path={ROUTES.noPermission} element={<NoPermissionPage />} />
      <Route path="*" element={<Navigate to={ROUTES.error} />} />
      <Route path={ROUTES.error} element={<ErrorPage />} />

      <Route element={<CleanLayout />}>
        <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={ROUTES.rules} element={<Rules />} />
      </Route>
    </Route>,
  ),
);

export default router;

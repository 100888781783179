import { closeAuthModal } from "../auth/user-slice";
import {
  removePostFromState,
  resetFeedPosts,
  resetOtherProfilePosts,
  resetProfilePosts,
} from "../posts/posts-slice";
import { api } from "./api";

export const userApi = api.injectEndpoints({
  tagTypes: ["profile", "other_profile"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => {
        return {
          url: "users/sign_in",
          method: "POST",
          body: { user: credentials },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(closeAuthModal());
          const { data: profile } = await queryFulfilled;
          dispatch(resetFeedPosts());
          dispatch(removePostFromState());
          dispatch(resetOtherProfilePosts());
          dispatch(resetProfilePosts());
          // as login return a profile we can set this value in cache for getSelfProfile request
          dispatch(
            api.util.upsertQueryData("getSelfProfile", undefined, profile),
          );
        } catch (error) {
          console.log(error);
        }
      },
      transformErrorResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.data;
      },
      // transformResponse(baseQueryReturnValue, meta) {
      //   console.log(baseQueryReturnValue);
      //   if (meta.response.headers.get("Authorization")) {
      //     save("accessToken", meta.response.headers.get("Authorization"));
      //     save("refreshToken", meta.response.headers.get("Refresh-Token"));
      //   }
      //   return baseQueryReturnValue;
      // },
    }),
    getSelfProfile: builder.query({
      query: () => ({
        url: "/profile",
        method: "GET",
      }),
      providesTags: ["profile"],
    }),
    signUp: builder.mutation({
      query: (credentials) => ({
        url: "users",
        method: "POST",
        body: { user: credentials },
      }),
      providesTags: ["profile"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(resetFeedPosts());
          dispatch(removePostFromState());
          dispatch(resetOtherProfilePosts());
          dispatch(resetProfilePosts());
          const { data: profile } = await queryFulfilled;
          // as login return a profile we can set this value in cache for getSelfProfile request
          dispatch(
            api.util.upsertQueryData("getSelfProfile", undefined, profile),
          );
        } catch (error) {
          console.log(error);
        }
      },
      // transformResponse(baseQueryReturnValue, meta) {
      //   if (meta.response.headers.get("Authorization")) {
      //     save("accessToken", meta.response.headers.get("Authorization"));
      //   }
      //   return baseQueryReturnValue;
      // },
      transformErrorResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.data;
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: "users/sign_out",
        method: "POST",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(resetFeedPosts());
          dispatch(removePostFromState());
          dispatch(resetOtherProfilePosts());
          dispatch(resetProfilePosts());
          dispatch(api.util.upsertQueryData("getSelfProfile", undefined, null));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    updateProfile: builder.mutation({
      query: (profile) => ({
        url: "profile",
        method: "PATCH",
        body: profile,
      }),
      invalidatesTags: ["profile"],
    }),
    uploadAttachment: builder.mutation({
      query: ({ file, attachment_type, actions, id }) => ({
        url: "profile/attachments",
        method: "POST",
        body: {
          file,
          attachment_type,
          actions,
          id,
        },
      }),
    }),
    getSelfProfileAttachments: builder.query({
      query: ({ attachment_type }) => ({
        url: `profile/attachments?attachment_type=${attachment_type}`,
        method: "GET",
        params: {
          attachment_type: attachment_type,
        },
      }),
    }),
    getOtherProfile: builder.query({
      query: (id) => ({
        url: `profiles/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "other_profile", id }],
    }),
    createRelation: builder.mutation({
      query: ({ username, relation_type }) => ({
        url: `/profiles/${username}/relation`,
        method: "POST",
        body: {
          relation_type,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: profile } = await queryFulfilled;
          // as login return a profile we can set this value in cache for getSelfProfile request
          dispatch(
            api.util.upsertQueryData("getOtherProfile", args.username, profile),
          );
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: (result, error, id) => [{ type: "other_profile", id }],
    }),
    removeRelation: builder.mutation({
      query: ({ username, relation_type }) => ({
        url: `/profiles/${username}/relation`,
        method: "DELETE",
        body: {
          relation_type,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: profile } = await queryFulfilled;
          // as login return a profile we can set this value in cache for getSelfProfile request
          dispatch(
            api.util.upsertQueryData("getOtherProfile", args.username, profile),
          );
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: (result, error, id) => [{ type: "other_profile", id }],
    }),
    getOtherProfileAttachments: builder.query({
      query: ({ attachment_type, username }) => ({
        url: `profiles/${username}/attachments?attachment_type=${attachment_type}`,
        method: "GET",
      }),
    }),
    askForUnblock: builder.mutation({
      query: ({ username }) => ({
        url: `profiles/${username}/ask_for_unblock`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { username }) => [
        { type: "other_profile", username },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSelfProfileQuery,
  useLoginMutation,
  useSignUpMutation,
  useLogoutMutation,
  useUpdateProfileMutation,
  useUploadAttachmentMutation,
  useGetSelfProfileAttachmentsQuery,
  useGetOtherProfileQuery,
  useCreateRelationMutation,
  useRemoveRelationMutation,
  useLazyGetOtherProfileAttachmentsQuery,
  useGetOtherProfileAttachmentsQuery,
  useAskForUnblockMutation,
} = userApi;
export const useGetSelfProfileState =
  userApi.endpoints.getSelfProfile.useQueryState;

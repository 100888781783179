import { selfProfileActionsValues as selfProfileValues } from "./options";

export const NOTIFICATIONS_ASIDE_WIDTH = 832;

export const GALLERY_ANIMATION_SPEED = 300;
export const GALLERY_SLIDES_TO_SHOW = 5;
export const GALLERY_SLIDES_TO_SCROLL = 1;
export const FULL_UPLOADED_IMAGE_PERCENT = 100;
export const IMAGE_GRID_COUNT = 2;
export const IMAGE_GRID_MORE_COUNT = 2;
export const MAX_POST_IMAGES_COUNT = 20;
export const MIN_POST_LENGHT_HIDE = 1500;

const PX_UNIT = "px";
const UPLOAD_BUTTON_TEXT = "Завантажити фото";

const calculateWidth = (height, ratio) => height * ratio;

const createCropState = (x, y, height, ratio) => ({
  x,
  y,
  height,
  width: calculateWidth(height, ratio),
  unit: PX_UNIT,
});

export const INITIAL_CROP_STATE_AVATAR = createCropState(10, 10, 150, 1);
export const INITIAL_CROP_STATE_COVER = createCropState(10, 10, 50, 3.91);

const createProfileImageConfig = (
  attachmentType,
  initialCropState,
  ratio,
  minHeight,
  direction,
) => ({
  attachment_type: attachmentType,
  initialCropState,
  uploadButtonText: UPLOAD_BUTTON_TEXT,
  imageRatio: ratio,
  minHeight,
  imageDirection: direction,
});

const PROFILE_IMAGE_CONFIG = createProfileImageConfig(
  "profile_image",
  INITIAL_CROP_STATE_AVATAR,
  1,
  150,
  "row",
);

const COVER_IMAGE_CONFIG = createProfileImageConfig(
  "cover_image",
  INITIAL_CROP_STATE_COVER,
  3.37,
  50,
  "column",
);

export const selfProfileModalConfig = {
  [selfProfileValues.edit_avatar]: PROFILE_IMAGE_CONFIG,
  [selfProfileValues.edit_cover]: COVER_IMAGE_CONFIG,
};

export const metadataTypes = {
  url_preview: "url_preview",
};

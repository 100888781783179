import { ReactComponent as Logo } from "@/assets/icons/logo-white.svg";
import { Carousel } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import ROUTES from "../../../routing/routes";
import { loginSlogans } from "../../../utils/constants/options";
import { getStartPageTitle } from "../../../utils/helpers";
import CreateAccount from "../create-account";
import { SignUpLinks } from "../links/sign-up-type-links";
import LoginComponent from "../login";
import { StartComponent } from "../register";
import RemindPassword from "../remind";
import useLogin from "./hooks/use-login";
import useSignUp from "./hooks/use-sign-up";
import styles from "./start.module.scss";

const Start = ({ type = "login", onChangeType }) => {
  const { handleLogin, loginError, isLoginLoading } = useLogin();
  const { handleSignUp, isSignUpLoading, signUpError } = useSignUp();

  const BottomLinks = () => {
    return (
      <div className={styles.links}>
        <Link to={ROUTES.privacyPolicy} target="_blank" className={styles.link}>
          Політика конфіденційності
        </Link>
        <Link to="mailto:admin@vmist.net" className={styles.link}>
          Зворотній зв’язок
        </Link>
      </div>
    );
  };

  const getComponentByParams = (type) => {
    switch (type) {
      case "start":
        return (
          <div className={styles.formWrap}>
            <div className={styles.title}>{getStartPageTitle(type)}</div>
            <StartComponent type={type} onChangeType={onChangeType} />
            <SignUpLinks type={type} onChangeType={onChangeType} />

            <BottomLinks />
          </div>
        );
      case "sign-up":
        return (
          <form className={styles.formWrap}>
            <div className={styles.title}>{getStartPageTitle(type)}</div>
            <CreateAccount
              onSubmit={handleSignUp}
              error={signUpError}
              isLoading={isSignUpLoading}
            />
            <SignUpLinks type={type} onChangeType={onChangeType} />
            <BottomLinks />
          </form>
        );
      case "remind":
        return (
          <form className={styles.formWrap}>
            <div className={styles.title}>{getStartPageTitle(type)}</div>
            <RemindPassword />
            <SignUpLinks type={type} onChangeType={onChangeType} />
            <BottomLinks />
          </form>
        );
      case "login":
      default:
        return (
          <form className={styles.formWrap}>
            <div className={styles.title}>{getStartPageTitle(type)}</div>
            <LoginComponent
              onChangeType={onChangeType}
              onSubmit={handleLogin}
              error={loginError}
              isLoading={isLoginLoading}
            />
            <SignUpLinks type={type} onChangeType={onChangeType} />
            <BottomLinks />
          </form>
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.bg}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.desc}>
          <Carousel
            className={styles.loginCarousel}
            autoplay={true}
            autoplaySpeed={5000}
            effect="fade"
            dotPosition="bottom"
            adaptiveHeight={true}
          >
            {loginSlogans.map(({ title, text }) => (
              <div className={styles.descSlide} key={title}>
                <div className={styles.descTitle}>{title}</div>
                <div className={styles.descText}>{text}</div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      {getComponentByParams(type)}
    </div>
  );
};

export { Start };

import { Button, Flex } from "antd";
import React from "react";

import LoginButtons from "../login-buttons";
import styles from "./register.module.scss";

const StartComponent = ({ onChangeType }) => {
  return (
    <Flex vertical gap={16}>
      <Button
        type="primary"
        size="xl"
        className={styles.registerButton}
        onClick={() => onChangeType("sign-up")}
      >
        Зареєструватись з Email
      </Button>
      <LoginButtons />
    </Flex>
  );
};

export { StartComponent };

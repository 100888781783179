import { Layout } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Outlet } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { resolutions } from "../../../utils/constants/variables";
import Aside from "../../ui/aside";
import Header from "../../ui/header";
import RightColumn from "../../ui/right-column";
import styles from "./default-layout.module.scss";

const { Content } = Layout;

const DefaultLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(
    window.innerWidth >= resolutions.xl,
  );

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const onToggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  const onResize = useCallback(() => {
    if (!isMobile) {
      setSidebarOpen(window.innerWidth >= resolutions.xl);
    }
  }, []);

  const debouncedResize = useDebouncedCallback(onResize, 100);

  useEffect(() => {
    window.addEventListener("resize", debouncedResize);
    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  const onSiderOutSideClick = () => {
    if (window.innerWidth < resolutions.xl) {
      setSidebarOpen(false);
    }
  };

  return (
    <Layout className={styles.wrap}>
      <Header onToggleSidebar={onToggleSidebar} />
      <Layout className={styles.layoutContent}>
        <Aside open={sidebarOpen} closeSidebar={closeSidebar} />
        <div className={styles.columnWrapper} onClick={onSiderOutSideClick}>
          <Content className={styles.column}>
            <Outlet />
          </Content>
        </div>
        <RightColumn />
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;

import { Button, Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  closeAuthModal,
  openAuthModal,
} from "../../../../features/auth/user-slice";
import { load, remove } from "../../../../utils/helpers/local-storage";
import { Start } from "../../../auth/start";
import styles from "./login-modal.module.scss";

const LoginModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.user.authModalOpen);
  const [type, setType] = useState("login");
  const showModal = () => {
    dispatch(openAuthModal());
    remove("accessToken");
    remove("refreshToken");
  };
  const handleCancel = () => {
    dispatch(closeAuthModal());
  };

  if (load("accessToken")) {
    return null;
  }

  return (
    <>
      <Button onClick={showModal} type={"primary"}>
        Увійти
      </Button>
      <Modal
        width={992}
        title={null}
        open={isModalOpen}
        footer={null}
        rootClassName={styles.loginModal}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <Start type={type} onChangeType={setType} />
      </Modal>
    </>
  );
};

export { LoginModal };

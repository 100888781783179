const iconMap = {
  "open-lock": () => import("../../../assets/icons/systems/open-lock.svg"),
  "locked-lock": () => import("../../../assets/icons/systems/locked-lock.svg"),
  "paper-clip": () => import("../../../assets/icons/systems/paper-clip.svg"),
  "message-question": () =>
    import("../../../assets/icons/systems/message-question.svg"),
  picture: () => import("../../../assets/icons/systems/picture.svg"),
  "video-play": () => import("../../../assets/icons/systems/video-play.svg"),
  "message-text": () =>
    import("../../../assets/icons/systems/message-text.svg"),
};

export default iconMap;

import trigger from "../../../../assets/icons/systems/menu.svg";
import styles from "./trigger.module.scss";

const Trigger = ({ onClick }) => {
  return (
    <div className={styles.button} onClick={onClick}>
      <img src={trigger} alt="Menu" />
    </div>
  );
};

export { Trigger };

import { Button, Dropdown, Space } from "antd";
import { TickCircle } from "iconsax-react";
import React, { useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

import styles from "./follow-button.module.scss";

const actions = {
  follow: {
    key: "follow",
    label: "Підписатись",
  },
  unfollow: {
    key: "unfollow",
    label: "Відписатись",
  },
  block: {
    key: "block",
    label: "Заблокувати",
  },
  unblock: {
    key: "unblock",
    label: "Розблокувати",
  },
  askForUnblock: {
    key: "askForUnblock",
    label: "Попросити розблокувати",
  },
  askedForUnblock: {
    key: "askedForUnblock",
    label: "Прохання розблокувати відправлено",
    disabled: true,
  },
};

const FollowButton = ({ relations, onClick, permissions }) => {
  // Memoize the dropdown menu items for block/unblock actions

  const menuItems = useMemo(() => {
    const items = [];

    if (!relations?.block) {
      items.push(actions.block);
    }

    if (permissions?.can_ask_for_unblock) {
      items.push(actions.askForUnblock);
    }

    if (permissions?.can_ask_for_unblock === false) {
      items.push(actions.askedForUnblock);
    }
    return items;
  }, [relations, permissions]);

  // Determine the label for the follow/unfollow button
  const buttonLabel = useMemo(() => {
    if (relations?.block) {
      return "Розблокувати";
    }
    return relations?.follow ? (
      <>
        <TickCircle size={16} /> Відслідковується
      </>
    ) : (
      "Підписатись"
    );
  }, [relations]);

  // Handle click events for the follow/unfollow button
  const onButtonClick = () => {
    if (relations?.block) {
      onClick({ key: actions.unblock.key });
    } else if (relations?.askForUnblock) {
      onClick({ key: actions.askForUnblock.key });
    } else if (relations?.follow) {
      onClick({ key: actions.unfollow.key });
    } else {
      onClick({ key: actions.follow.key });
    }
  };

  return (
    <Space>
      <div className={styles.follow}>
        <Button
          type={relations?.follow && !relations?.block ? "default" : "primary"}
          size="large"
          onClick={onButtonClick}
          className={styles.button}
        >
          <span className={styles.label}>{buttonLabel}</span>
        </Button>
      </div>
      <div className={styles.dropdown}>
        <Dropdown
          disabled={!menuItems.length}
          arrow
          placement={"bottomRight"}
          menu={{
            items: menuItems,
            onClick: onClick,
          }}
          trigger={["hover"]}
        >
          <Button
            size="large"
            type="text"
            icon={<BsThreeDotsVertical size={20} />}
          />
        </Dropdown>
      </div>
    </Space>
  );
};

export { FollowButton };

import { createConsumer } from "@anycable/web";
import React, { createContext, useEffect, useState } from "react";

import { useGetSelfProfileState } from "../../features/api/user-api";
import { load } from "../helpers/local-storage";
import { refreshTokensFromSocket } from "../helpers/utils";

export const CableContext = createContext(null);

const CableProvider = ({ children }) => {
  const [cable, setCable] = useState(null);
  const { data: profile } = useGetSelfProfileState();

  useEffect(() => {
    if (profile) {
      const token = load("accessToken");
      setCable(
        createConsumer(`${process.env.REACT_APP_WS_URL}?token=${token}`, {
          tokenRefresher: refreshTokensFromSocket,
          pingInterval: 10000,
        }),
      );
    } else {
      setCable(null);
    }
  }, [profile]);

  return (
    <CableContext.Provider value={cable}>{children}</CableContext.Provider>
  );
};

export default CableProvider;

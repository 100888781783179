import { Button } from "antd";
import propTypes from "prop-types";
import React from "react";

import styles from "./social-login-button.module.scss";

const SocialLoginButton = ({ icon, onClick }) => {
  return (
    <Button className={styles.wrapper} onClick={onClick}>
      <div className={styles.icon}>{icon}</div>
      <span className={styles.text}>Продовжити з Google</span>
    </Button>
  );
};

SocialLoginButton.propTypes = {
  icon: propTypes.node,
  onClick: propTypes.func,
};

export { SocialLoginButton };

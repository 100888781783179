import { Divider, Flex } from "antd";

import { GoogleAuth } from "../google-auth";

const LoginButtons = () => {
  return (
    <Flex vertical gap={16} align={"center"}>
      <Divider plain>або</Divider>
      <GoogleAuth />
    </Flex>
  );
};

export default LoginButtons;

import { Button, Flex, Tooltip } from "antd";
import React from "react";

import cn from "../../../utils/helpers/className";
import SvgIcon from "../svg-icon/svg-icon";
import styles from "./comment-form-footer.module.scss";
import { TfiClose } from "react-icons/tfi";

const CommentFormFooter = ({
  onUploadClick,
  onCancel,
  isSubmitDisabled,
  isEditing,
}) => (
  <div className={styles.wrapper}>
    <Flex className={styles.settings} align="center">
      <Button
        onClick={onUploadClick}
        type="default"
        size="large"
        className={styles.button}
      >
        <SvgIcon
          className={cn(styles.icon, styles.paperClip)}
          name={"paper-clip"}
          width={20}
          height={20}
        />
      </Button>
    </Flex>
    <div className={styles.buttons}>
      <Button
        className={cn(styles.buttonItem, styles.buttonCancel)}
        type="default"
        size="large"
        onClick={onCancel}
      >
        <span>
          <span className={styles.cancelLabel}>
            {isEditing ? "Скасувати" : "Очистити"}
          </span>
          <span className={styles.cancelIcon}>
            <TfiClose size="22" color="#717183" />
          </span>
        </span>
      </Button>
      <Tooltip title="Ctrl + Enter">
        <Button
          className={cn(styles.buttonItem, styles.buttonSubmit)}
          disabled={isSubmitDisabled}
          type="primary"
          size="large"
          htmlType={"submit"}
        >
          {isEditing ? "Зберегти" : "Опублікувати"}
        </Button>
      </Tooltip>
    </div>
  </div>
);

export { CommentFormFooter };

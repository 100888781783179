import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";

import {
  fetchNextPosts,
  fetchPosts,
  fetchRemovePost,
  fetchUpdateVote,
} from "../features/posts/posts-actions";
import {
  isFetchingNextOtherProfilePosts,
  resetOtherProfilePosts,
  selectHasMoreOtherProfilePosts,
  selectOtherProfileError,
  selectOtherProfileIsLoading,
  selectOtherProfilePostLastId,
  selectOtherProfilePosts,
} from "../features/posts/posts-slice";
import ROUTES from "../routing/routes";

const useOtherProfilePosts = ({ username }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const posts = useSelector(selectOtherProfilePosts);
  const post_last_id = useSelector(selectOtherProfilePostLastId);
  const hasMorePosts = useSelector(selectHasMoreOtherProfilePosts);
  const isFetchingNextPage = useSelector(isFetchingNextOtherProfilePosts);
  const error = useSelector(selectOtherProfileError);
  const isLoading = useSelector(selectOtherProfileIsLoading);
  const searchValue = useSelector((state) => state.posts.searchPostsQuery);

  useEffect(() => {
    if (username && !posts.length && !isLoading) {
      dispatch(
        fetchPosts({
          from_id: null,
          source: "otherProfilePosts",
          username,
        }),
      );
    }
  }, [username]);

  useEffect(() => {
    if (
      username &&
      searchValue?.query !== null &&
      searchValue?.page === "otherProfilePosts"
    ) {
      console.log("me");
      dispatch(resetOtherProfilePosts());
      dispatch(
        fetchPosts({
          from_id: null,
          source: "otherProfilePosts",
          ...(searchValue?.query && { q: searchValue?.query }),
          username,
        }),
      );
    }
  }, [searchValue]);

  useEffect(() => {
    return () => {
      dispatch(resetOtherProfilePosts());
    };
  }, [username]);

  const getNextPosts = () => {
    if (post_last_id) {
      dispatch(
        fetchNextPosts({
          from_id: post_last_id,
          source: "otherProfilePosts",
          username,
          ...(searchValue?.query && { q: searchValue?.query }),
        }),
      );
    }
  };

  const onComment = ({ id, username }) => {
    navigate(generatePath(ROUTES.post, { id, username }), {
      state: { scrollPos: window.scrollY },
    });
  };

  const onPostAction = useCallback(
    ({ id, type, metadata }) => {
      switch (type) {
        case "comment": {
          onComment({ id: metadata.seq_id, username: metadata.username });
          break;
        }
        case "vote": {
          const { type: voteType } = metadata;
          dispatch(fetchUpdateVote({ postId: id, voteType }));
          break;
        }
        case "remove": {
          dispatch(fetchRemovePost({ postId: id }));
          break;
        }
        default:
          break;
      }
    },
    [dispatch, username],
  );

  return {
    posts,
    isLoading,
    error,
    onPostAction,
    getPosts: getNextPosts,
    hasMorePosts: hasMorePosts,
    isFetchingNextPage,
  };
};

export default useOtherProfilePosts;

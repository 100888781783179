const ROUTES = {
  profile: "/profile",
  otherProfile: "/:slag",
  settings: "/settings",
  home: "/",
  feed: "/feed/:type?",
  post: "/:username/posts/:id",
  error: "/error",
  notFoundPage: "/static/404",
  noPermission: "/static/403",
  privacyPolicy: "/static/privacy-policy",
  rules: "/static/rules",
};

export default ROUTES;

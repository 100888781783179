import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { useGetSelfProfileState } from "../features/api/user-api";
import {
  fetchNextPosts,
  fetchPosts,
  fetchRemovePost,
  fetchUpdateVote,
} from "../features/posts/posts-actions";
import {
  isFetchingNextFeedPosts,
  resetFeedPosts,
  selectFeedError,
  selectFeedIsLoading,
  selectFeedPostLastId,
  selectFeedPosts,
  selectHasMoreFeedPosts,
} from "../features/posts/posts-slice";
import ROUTES from "../routing/routes";

const useFeedPosts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const posts = useSelector(selectFeedPosts);
  const post_last_id = useSelector(selectFeedPostLastId);
  const hasMorePosts = useSelector(selectHasMoreFeedPosts);
  const isFetchingNextPage = useSelector(isFetchingNextFeedPosts);
  const error = useSelector(selectFeedError);
  const isLoading = useSelector(selectFeedIsLoading);
  const searchValue = useSelector((state) => state.posts.searchPostsQuery);

  const { data: profile } = useGetSelfProfileState();
  //modified for not logged in user
  const { type } = useParams();

  useEffect(() => {
    if (!posts?.length && !isLoading) {
      dispatch(
        fetchPosts({
          from_id: null,
          source: "feedPosts",
          ...(type && { category: type }),
        }),
      );
    }
  }, [profile, type, searchValue.query]);

  useEffect(() => {
    if (searchValue?.query !== null && searchValue?.page === "feedPosts") {
      dispatch(resetFeedPosts());
      dispatch(
        fetchPosts({
          from_id: null,
          source: "feedPosts",
          ...(searchValue?.query && { q: searchValue?.query }),
          category: "fresh",
        }),
      );
    }
  }, [searchValue.page, searchValue.query]);

  const getNextPosts = () => {
    if (post_last_id) {
      dispatch(
        fetchNextPosts({
          from_id: post_last_id,
          source: "feedPosts",
          ...(searchValue?.query && { q: searchValue?.query }),
          ...(type && { category: type }),
        }),
      );
    }
  };

  const onComment = ({ id, metadata: { username, seq_id } }) => {
    navigate(generatePath(ROUTES.post, { id: seq_id, username }), {
      state: { scrollPos: window.scrollY },
    });
  };

  const onPostAction = useCallback(
    ({ id, type, metadata }) => {
      switch (type) {
        case "comment": {
          onComment({ id, metadata });
          break;
        }
        case "vote": {
          const { type: voteType } = metadata;
          dispatch(fetchUpdateVote({ postId: id, voteType }));
          break;
        }
        case "remove": {
          dispatch(fetchRemovePost({ postId: id }));
          break;
        }
        default:
          break;
      }
    },
    [dispatch],
  );

  return {
    posts,
    isLoading,
    error,
    onPostAction,
    getPosts: getNextPosts,
    hasMorePosts: hasMorePosts,
    isFetchingNextPage,
  };
};

export default useFeedPosts;

import { Skeleton } from "antd";

import { Avatar } from "../../ui/avatar";
import { ProfileBackdrop } from "./components/profile-backdrop";
import { ProfileStats } from "./components/profile-stats";
import styles from "./profile-header.module.scss";

const ProfileHeader = ({
  profile,
  isLoading,
  topRightComponent,
  bioComponent,
  onAvatarClick,
}) => {
  if (isLoading)
    return (
      <div className={styles.card}>
        <Skeleton avatar paragraph={{ rows: 3 }} />
      </div>
    );

  const profileAvatar = profile?.profile_image?.medium?.url;

  return (
    <div className={styles.card}>
      <ProfileBackdrop profileBg={profile?.cover_image?.large?.url} />
      <div className={styles.header}>
        {/* <div className={styles.photo}> */}
        <Avatar
          profile_avatar={profileAvatar}
          className={styles.photo}
          onClick={onAvatarClick}
        />
        {/* </div> */}
        <div className={styles.actionsTop}>{topRightComponent}</div>
        <div className={styles.infoTop}>
          <div className={styles.name}>{profile?.display_name}</div>
          <div className={styles.statsTop}>
            <ProfileStats
              follows_count={profile?.followers_count}
              posts_count={profile?.posts_count}
            />
          </div>
        </div>
      </div>
      {bioComponent}
      <div className={styles.actionsBottom}>
        <div className={styles.statsBottom}>
          <ProfileStats
            follows_count={profile?.followers_count}
            posts_count={profile?.posts_count}
          />
        </div>
        {topRightComponent}
      </div>
    </div>
  );
};

export default ProfileHeader;

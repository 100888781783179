import { isMobile } from "react-device-detect";

import { LinkDesktopPreview } from "../link-desktop-preview/link-desktop-preview";
import { LinkMobilePreview } from "../link-mobile-preview/link-mobile-preview";

const LinkPreview = ({
  url,
  title,
  description,
  external_image,
  label,
  type,
}) => {
  return (
    <>
      {isMobile && (
        <LinkMobilePreview
          type={type}
          url={url}
          label={label}
          title={title}
          description={description}
          external_image={external_image?.url}
        />
      )}
      {!isMobile && (
        <LinkDesktopPreview
          type={type}
          url={url}
          label={label}
          title={title}
          description={description}
          external_image={external_image?.url}
        />
      )}
    </>
  );
};

export { LinkPreview };

import { Input } from "antd";
import { CloseCircle, SearchNormal1 } from "iconsax-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useMatch } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import {
  resetFeedPosts,
  resetOtherProfilePosts,
  resetProfilePosts,
  setSearchPostsQuery,
} from "../../../../features/posts/posts-slice";
import ROUTES from "../../../../routing/routes";
import * as $vars from "../../../../utils/constants/variables";
import cn from "../../../../utils/helpers/className";
import styles from "./search.module.scss";

const HeaderSearch = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isFeedPage = useMatch(ROUTES.feed);
  const isProfilePage = useMatch(ROUTES.profile);
  const isOtherProfilePage = useMatch(ROUTES.otherProfile);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchValue = useSelector((state) => state.posts.searchPostsQuery);

  const determinePage = () => {
    if (isFeedPage) return "feedPosts";
    if (isProfilePage) return "profilePosts";
    if (isOtherProfilePage) return "otherProfilePosts";
    return null;
  };

  const openSearch = () => {
    setIsSearchOpen(true);
  };

  const closeSearch = () => {
    setIsSearchOpen(false);
  };

  const onSearchBlur = () => {
    if (!searchValue) {
      closeSearch();
    }
  };

  useEffect(() => {
    if (searchValue.query) {
      dispatch(resetFeedPosts());
      dispatch(resetProfilePosts());
      dispatch(resetOtherProfilePosts());
      dispatch(
        setSearchPostsQuery({
          query: null,
          page: null,
          displayValue: "",
        }),
      );
    }
  }, [location]);

  const debouncedDispatch = useDebouncedCallback((value, page) => {
    dispatch(
      setSearchPostsQuery({
        query: value,
        page,
        displayValue: value,
      }),
    );
  }, 500);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    const page = determinePage();
    dispatch(
      setSearchPostsQuery({
        query: searchValue?.query,
        page: searchValue?.page,
        displayValue: value,
      }),
    );
    if (!value) {
      debouncedDispatch(value, page);
    }
  };

  const onEnterPress = () => {
    const page = determinePage();
    debouncedDispatch(searchValue?.displayValue, page);
  };

  return (
    <div
      className={cn(
        styles.searchWrap,
        isSearchOpen ? styles.searchWrapShow : "",
      )}
    >
      <Input
        allowClear
        placeholder={`Пошук по ${isFeedPage ? "всім постам" : "профілю"}`}
        className={cn(
          styles.searchInput,
          isSearchOpen ? styles.searchInputShow : "",
        )}
        value={searchValue?.displayValue}
        onPressEnter={onEnterPress}
        onBlur={onSearchBlur}
        onChange={handleSearchChange}
      />
      <div className={styles.searchButton}>
        {!isSearchOpen && (
          <SearchNormal1
            onClick={openSearch}
            size="24"
            color={$vars.colors.grey}
          />
        )}
        {isSearchOpen && (
          <CloseCircle
            onClick={closeSearch}
            size="24"
            color={$vars.colors.grey}
          />
        )}
      </div>
    </div>
  );
};

export { HeaderSearch };

import { Popover, Typography } from "antd";
import { Youtube } from "iconsax-react";

import styles from "./link-desktop-preview.module.scss";

export const LinkDesktopPreview = ({
  url,
  title,
  description,
  external_image,
  label,
  type,
}) => {
  return (
    <span className={styles.link}>
      <Popover
        arrow={false}
        placement={"bottomLeft"}
        content={
          <a href={url} target="_blank" rel="noreferrer">
            <div className={styles.wrapper}>
              {external_image && (
                <div className={styles.image}>
                  <img src={external_image} alt={title} />
                </div>
              )}
              <div className={styles.body}>
                <Typography.Paragraph
                  ellipsis={{
                    rows: 1,
                    expandable: false,
                    symbol: "...",
                  }}
                  className={styles.title}
                >
                  {title}
                </Typography.Paragraph>
                <Typography.Paragraph
                  ellipsis={{
                    rows: 2,
                    expandable: false,
                    symbol: "...",
                  }}
                  className={styles.description}
                >
                  {description}
                </Typography.Paragraph>
              </div>
            </div>
          </a>
        }
      >
        <a href={url} target="_blank" rel="noreferrer">
          {type === "youtube_video" && (
            <span className={styles.linkImg}>
              <Youtube size="18" color="#FF0000" variant="Bold" />
            </span>
          )}
          {label}
        </a>
      </Popover>
    </span>
  );
};

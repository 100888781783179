import styles from './rules.module.scss';

export const Rules = () => {
  return (
    <div className={styles.card}>
      <h1 className={styles.title}>Правила</h1>
      <p>Наразі ми працюємо над контентом для цієї сторінки</p>
    </div>
  )
}

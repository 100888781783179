import ROUTES from "../../routing/routes";
import { load, save } from "./local-storage";

export const getScrollKey = (location) => {
  const paths = [ROUTES.home, ROUTES.profile];
  return paths.includes(location.pathname) ? location.pathname : location.key;
};

export function hasJWT() {
  return load("accessToken");
}

export const checkAndConvertToEmbedYoutube = (url) => {
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const match = url.match(youtubeRegex);

  if (match && match[1]) {
    return `https://www.youtube.com/embed/${match[1]}`;
  }

  return false;
};

export const refreshTokensFromSocket = async (transport) => {
  const refreshToken = load("refreshToken");
  const accessToken = load("accessToken");
  const response = await fetch(`${process.env.REACT_APP_API_URL}profile`, {
    headers: {
      ["Refresh-Token"]: `${refreshToken}`,
      ["Authorization"]: `Bearer ${accessToken}`,
    },
  });

  const newAccessToken = response.headers.get("authorization");
  const newRefreshToken = response.headers.get("refresh-token");

  if (newAccessToken) {
    save("accessToken", newAccessToken);
    transport.setURL(`${process.env.REACT_APP_WS_URL}?token=${newAccessToken}`);
  }
  if (newRefreshToken) {
    save("refreshToken", newRefreshToken);
  }
};

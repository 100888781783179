import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";

import { getScrollKey } from "../../utils/helpers/utils";

const ScrollRestorationComponent = () => {
  return (
    <>
      <ScrollRestoration getKey={getScrollKey} />
      <Outlet />
    </>
  );
};

export { ScrollRestorationComponent };

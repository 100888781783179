import profileBgDefault from "@/assets/images/profile-bg.svg";
import React from "react";

import styles from "./profile-backdrop.module.scss";

const ProfileBackdrop = ({ profileBg, height }) => {
  return (
    <div
      className={styles.wrapper}
      style={{
        height,
      }}
    >
      {profileBg && <img src={profileBg} alt={"bg"} />}
      {!profileBg && <img src={profileBgDefault} alt={"bg"} />}
    </div>
  );
};

export { ProfileBackdrop };

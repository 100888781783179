import { Dropdown, Tooltip } from "antd";
import { Link, useMatch, useNavigate } from "react-router-dom";

import {
  useGetSelfProfileQuery,
  useLogoutMutation,
} from "../../../features/api/user-api";
import ROUTES from "../../../routing/routes";
import { load, remove } from "../../../utils/helpers/local-storage";
import { Notifications } from "../../notifications/notifications";
import { Avatar } from "../avatar";
import { LogoHeader } from "../logo/logo-header";
import { LoginModal } from "../modals/login-modal";
import { headerMenuActions, headerMenuItems } from "./constants";
import styles from "./header.module.scss";
import { HeaderSearch } from "./search/search";
import { Trigger } from "./trigger/trigger";

const Header = ({ onToggleSidebar }) => {
  const navigate = useNavigate();
  const isProfilePage = useMatch(ROUTES.profile);
  const { data } = useGetSelfProfileQuery(undefined, {
    skip: !load("accessToken"),
  });

  const [logoutTrigger] = useLogoutMutation();

  const onMenuItemClick = async ({ key }) => {
    switch (key) {
      case headerMenuActions.logout.key: {
        remove("accessToken");
        remove("refreshToken");
        await logoutTrigger();
        if (isProfilePage) navigate(ROUTES.home);
      }
    }
  };

  const profile_avatar = data?.profile_image?.small?.url;

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.logoContainer}>
          <Trigger onClick={onToggleSidebar} />
          <Link to={ROUTES.home}>
            <LogoHeader />
          </Link>
        </div>
        <div className={styles.controlsContainer}>
          {data?.username && (
            <>
              <HeaderSearch />
              <div className={styles.controlsIcons}>
                <Tooltip title={"Незабаром буде"}>
                  <span>
                    <Notifications />
                  </span>
                </Tooltip>

                <Dropdown
                  className={styles.profileButton}
                  menu={{
                    items: headerMenuItems,
                    onClick: onMenuItemClick,
                  }}
                  trigger={["click"]}
                >
                  <span>
                    <Avatar profile_avatar={profile_avatar} className={styles.headerAvatar} />
                  </span>
                </Dropdown>
              </div>
            </>
          )}
          {!data?.username && (
            <div className={styles.signInContainer}>
              <LoginModal />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

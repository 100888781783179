import React from "react";

import noPostsImage from "../../../assets/images/space-man.png";
import styles from "./empty-feed.module.scss";

const EmptyFeed = () => {
  return (
    <div className={styles.wrapper}>
      <img src={noPostsImage} alt="No posts" className={styles.image} />{" "}
      <div className={styles.text}>У стрічці ще немає публікацій</div>
    </div>
  );
};

export { EmptyFeed };

import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { FormattedMetadata } from "../../formatted-metadata";
import { Avatar } from "../avatar";
import styles from "./modal-image-user-info.module.scss";

export function ModalImageUserInfo({ name, avatar, date, body, metadata }) {
  return (
    <div className={styles.modalPostInfo}>
      <Avatar profile_avatar={avatar} className={styles.modalPostAvatar} />
      <div className={styles.modalPostInfoBody}>
        <div className={styles.modalPostHeader}>
          <Link className={styles.modalPostAuthor} to={"#"}>
            {name}
          </Link>
          <div className={styles.modalPostDate}>{date}</div>
        </div>
        <Typography.Paragraph
          className={styles.modalPostText}
          ellipsis={{
            rows: 1,
          }}
        >
          <FormattedMetadata metadata={metadata} text={body} oneLine />
        </Typography.Paragraph>
      </div>
    </div>
  );
}
